<template>
  <div>
    <main v-if="isShopify">
      <div class="page-wrapper-shopify is-full-screen">
        <!-- <div class="overlay" :class="{ isOpen: miniVariant }"></div> -->
        <!-- ======================== Order List Table ============================= -->
        <div class="container fluid"></div>
        <!-- ======================================= -->
        <div class="container fluid">
          <div class="row">
            <div class="col-sm-12">
              <div
                class="Polaris-Card cardTable"
                v-if="listOfOrders.length > 0"
              >
                <table
                  class="Polaris-DataTable__Table shopify-table"
                  v-if="isShopify"
                >
                  <thead>
                    <tr>
                      <th
                        data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header Polaris-DataTable__Cell--status header"
                        scope="col"
                      >
                        ORDER NO.
                      </th>
                      <th
                        data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--status header"
                        scope="col"
                      >
                        STATUS
                      </th>
                      <th
                        data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--status header"
                        scope="col"
                      >
                        ORDER DATE
                      </th>

                      <th
                        data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--status header"
                        scope="col"
                      >
                        TOTAL
                      </th>

                      <th
                        data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--status header"
                        scope="col"
                      >
                        SLA
                      </th>
                      <th
                        data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--status header"
                        scope="col"
                      >
                        BUYER NAME
                      </th>
                      <th
                        data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--status header"
                        scope="col"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="Polaris-DataTable__TableRow Polaris-DataTable--hoverable each-row"
                      v-for="order in listOfOrders"
                      :key="order.external_id"
                    >
                      <th
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--eachOrder"
                        scope="row"
                      >
                        <span class="shopify-table-title green-text">
                          {{ order.external_id }}</span
                        >
                      </th>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--eachOrder"
                      >
                        <span
                          v-if="order.status == 0"
                          class="Polaris-Badge Polaris-Badge--statusSuccess Polaris-Badge--status Unfullfilled"
                          ><span class="Polaris-VisuallyHidden"
                            >Unfullfilled</span
                          >Unfullfilled</span
                        >
                        <span
                          v-if="order.status == 1"
                          class="Polaris-Badge Polaris-Badge--statusInfo Polaris-Badge--status"
                          ><span class="Polaris-VisuallyHidden"
                            >Processing </span
                          >Processing</span
                        >
                        <span
                          v-if="order.status == 2"
                          class="Polaris-Badge Polaris-Badge--statusSuccess Polaris-Badge--progressComplete Polaris-Badge--status"
                        >
                          <span class="Polaris-VisuallyHidden">Fullfilled</span>
                          Fullfilled</span
                        >
                        <span
                          v-if="order.status == 3"
                          class="Polaris-Badge Polaris-Badge--status"
                          ><span class="Polaris-VisuallyHidden">Cancle </span
                          >Canceled</span
                        >
                        <span
                          v-if="order.status == 4"
                          class="Polaris-Badge Polaris-Badge--statusSuccess Polaris-Badge--progressComplete Polaris-Badge--status"
                        >
                          <span class="Polaris-VisuallyHidden"
                            >Waiting for tracking
                          </span>
                          Waiting for tracking
                        </span>
                        <div id="PolarisPortalsContainer"></div>
                      </td>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--eachOrder"
                      >
                        {{
                          order.created_at ? formatDate(order.created_at) : "-"
                        }}
                      </td>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--eachOrder"
                      >
                        {{ toCurrency(order.total_price, order.currency) }}
                      </td>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--eachOrder"
                      >
                        <span
                          v-if="order.status == 2 || order.status == 4"
                          class="Polaris-Badge Polaris-Badge--Unfullfilled day"
                        >
                          On time
                        </span>
                        <span
                          v-else-if="order.status == 3"
                          class="Polaris-Badge Polaris-Badge--Unfullfilled out"
                        >
                          Out of SLA
                        </span>

                        <span
                          v-else-if="
                            Math.abs(order.delivery_time_remaining >= 86400)
                          "
                          class="Polaris-Badge Polaris-Badge--Unfullfilled day"
                        >
                          {{
                            Math.round(order.delivery_time_remaining / 86400)
                          }}
                          Days
                        </span>
                        <span
                          v-else-if="
                            Math.abs(
                              order.delivery_time_remaining < 86400 &&
                                order.delivery_time_remaining > -86400
                            )
                          "
                          class="Polaris-Badge Polaris-Badge--Unfullfilled remaining"
                        >
                          {{
                            Math.round(order.delivery_time_remaining / 86400)
                          }}
                          Days
                        </span>
                        <span
                          v-else-if="
                            order.status !== 2 &&
                            order.status !== 3 &&
                            order.delivery_time_remaining < -86400
                          "
                          class="Polaris-Badge Polaris-Badge--Unfullfilled out"
                        >
                          {{
                            Math.round(order.delivery_time_remaining / 86400)
                          }}
                          Days
                        </span>
                      </td>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--name green-text underline"
                      >
                        {{ order.customer.name }}
                      </td>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop"
                      >
                        <main>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn light icon v-bind="attrs" v-on="on">
                                <span class="material-symbols-outlined">
                                  more_vert
                                </span>
                              </v-btn>
                            </template>

                            <v-list>
                              <div>
                                <v-list-item v-if="order.invoice_url">
                                  <td class="dots">
                                    <a :href="order.invoice_url" target="_blank"
                                      >Print invoice</a
                                    >
                                  </td>
                                </v-list-item>
                                <v-list-item
                                  v-else
                                  aria-disabled="true"
                                  style="color: gray"
                                >
                                  <td>generating invoice</td>
                                </v-list-item>
                              </div>
                              <v-list-item>
                                <td class="dots">
                                  <a
                                    @click="
                                      showTrackingModal(order.external_id)
                                    "
                                    >Add tracking information</a
                                  >
                                </td>
                              </v-list-item>
                            </v-list>
                          </v-menu>

                          <transition name="fade" appear>
                            <div
                              class="modal-backdrop"
                              v-if="showModal"
                              @click="showModal = false"
                            ></div>
                          </transition>
                          <transition name="slide" appear>
                            <div
                              class="modal"
                              style="
                                transform-origin: center center 0px;
                                max-width: 640px;
                              "
                              v-if="showModal"
                            >
                              <div class="modal-header">
                                <slot name="header">
                                  <span class="trackInfo"
                                    >Add tracking information</span
                                  >
                                </slot>
                              </div>
                              <div class="modal-body">
                                <slot name="body"> </slot>

                                <form>
                                  <label class="tracking"> Carrier name</label>
                                  <input
                                    :type="type"
                                    :name="id"
                                    :id="id"
                                    :value="value"
                                    :required="required"
                                    placeholder="Ex. UPS"
                                    class="custom-input without-icon"
                                    :class="{
                                      hasError: hasError,
                                      disabled: disabled,
                                      hasInlineButton: hasInlineButton,
                                    }"
                                    @input="
                                      updateTrackingInfoValue(
                                        'carrier_name',
                                        $event.target.value
                                      )
                                    "
                                    @keyup="$emit('check-input')"
                                    :maxlength="maxlength ? maxlength : ''"
                                    :disabled="disabled"
                                  />
                                  <label class="tracking"
                                    >Tracking number</label
                                  >

                                  <input
                                    :type="type"
                                    :name="id"
                                    :id="id"
                                    :value="value"
                                    :required="required"
                                    placeholder="H00A0A0003981320"
                                    class="custom-input without-icon"
                                    :class="{
                                      hasError: hasError,
                                      disabled: disabled,
                                      hasInlineButton: hasInlineButton,
                                    }"
                                    @input="
                                      updateTrackingInfoValue(
                                        'tracking_number',
                                        $event.target.value
                                      )
                                    "
                                    @keyup="$emit('check-input')"
                                    :maxlength="maxlength ? maxlength : ''"
                                    :disabled="disabled"
                                  />
                                  <label class="tracking">Tracking URL</label>

                                  <input
                                    :type="type"
                                    :name="id"
                                    :id="id"
                                    :value="value"
                                    :required="required"
                                    placeholder="Ex. https://web.ups.net/tracking/reference/ç/"
                                    class="custom-input without-icon"
                                    :class="{
                                      hasError: hasError,
                                      disabled: disabled,
                                      hasInlineButton: hasInlineButton,
                                    }"
                                    @input="
                                      updateTrackingInfoValue(
                                        'tracking_url',
                                        $event.target.value
                                      )
                                    "
                                    @keyup="$emit('check-input')"
                                    :maxlength="maxlength ? maxlength : ''"
                                    :disabled="disabled"
                                  />
                                </form>
                                <div>
                                  <button
                                    class="vgang-button-2 inline mr-6 mt-4"
                                    :class="{
                                      disabled: disableSaveChange,
                                      active: !disableSaveChange,
                                      outlined: disableSaveChange,
                                    }"
                                    :disabled="disableSaveChange"
                                    @click="saveChange()"
                                  >
                                    Save Changes
                                  </button>
                                  <button
                                    class="cancel inline mr-6 mt-4"
                                    :class="{
                                      disabled: disableSaveChange,
                                      active: !disableSaveChange,
                                      outlined: disableSaveChange,
                                    }"
                                    :disabled="disableSaveChange"
                                    @click="showModal = false"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>

                              <button
                                class="closeBtn"
                                @click="showModal = false"
                              >
                                <img
                                  src="@/assets/img/close.png"
                                  alt="Close"
                                  class="close"
                                  @click="dialog = false"
                                />
                              </button>
                            </div>
                          </transition>
                        </main>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <div class="empty-space">
                  <h2 class="_title">You don't have any order yet.</h2>
                  <p class="_desc">
                    Invite your dropshippers who love selling your products
                    online to VGANG <br />
                    and earn up to 30% of the annual plans they buy for every
                    new dropshipper you <br />bring to VGANG!
                  </p>
                  <img
                    src="@/assets/img/vgang-pale-bg.png"
                    alt="vgang"
                    class="_bg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-row v-if="listOfOrders.length > 7">
          <v-col cols="12" sm="12" md="12">
            <!-- <v-pagination
              v-model="page"
              :length="page_size"
              :total-visible="7"
              @input="next"
              color="#1f2936"
            ></v-pagination> -->
          </v-col>
        </v-row>
        <!-- ======================== Order List Table ============================= -->
      </div>
    </main>
    <main v-if="!isShopify">
      <div v-if="userType == 'supplier'">
        <supplierNavigation />
        <v-main app>
          <div class="page-wrapper">
            <!-- <div class="overlay" :class="{ isOpen: miniVariant }"></div> -->
            <!-- ======================== Order List Table ============================= -->
            <div class="assign-category-search-container">
              <div class="search-container">
                <CustomInput
                  type="text"
                  id="search"
                  icon="search"
                  v-model="searchQuery"
                  :hasInlineButton="true"
                  placeholder="Search your products"
                  :required="false"
                />
                <button class="vgang-button inline">Search</button>
              </div>
              <span class="total-products">Status select box goes here</span>
            </div>
            <!-- ======================================= -->
            <table class="orderlist-table">
              <thead class="header">
                <tr>
                  <th style="text-align: center">ORDER NO.</th>
                  <th style="text-align: center">STATUS</th>
                  <th style="text-align: center">ORDER DATE</th>
                  <th style="text-align: center">TOTAL</th>
                  <th style="text-align: center">SLA</th>
                  <th style="text-align: center">BUYER NAME</th>

                  <th style="text-align: center">Fullfill</th>
                </tr>
              </thead>
              <tbody class="body">
                <tr
                  class="each-row"
                  v-for="order in listOfOrders"
                  :key="order.external_id"
                >
                  <td style="text-align: center">{{ order.external_id }}</td>
                  <td style="text-align: center">
                    <span class="status" :class="order.statusClass"
                      ><span class="material-icons status-icon">{{
                        order.statusIcon
                      }}</span>
                      {{ order.status }}</span
                    >
                  </td>
                  <td style="text-align: center">
                    {{ order.orderDate ? order.orderDate : "" }}
                  </td>

                  <td style="text-align: center">{{ order.total_price }}</td>
                  <td style="text-align: center">{{ order.total_price }}</td>
                  <td style="text-align: center" class="green-text underlined">
                    {{ order.customer.city }}
                  </td>
                  <td style="text-align: center">Overdue</td>
                  <td style="text-align: center">
                    <router-link
                      :to="'/supplier/order-detail/' + order.external_id"
                    >
                      <span class="button">View</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-row> </v-row>
            <!-- ======================== Order List Table ============================= -->
          </div>
        </v-main>
      </div>
      <div class="container" v-else>
        <div class="row">
          <div class="col-sm-12">
            <div class="no-access">You don't have access to this panel</div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
// Services
import CommonData from "@/services/CommonData";
import CustomInput from "@/components/CustomInput.vue";
import supplierNavigation from "@/components/navigation/supplier.vue";

import SupplierOrders from "@/services/SupplierOrders";
import SupplierOrderTrackingInfo from "@/services/SupplierTrackingInfo";
import { setVariable } from "@/utils/conditionalStoreVariables";

export default {
  components: {
    CustomInput,
    supplierNavigation,
    SupplierOrders,
  },
  data() {
    return {
      items: [],
      userType: "supplier",
      listOfOrders: [],
      isShopify: false,
      search: "",
      searchQuery: "",
      dialog: "",
      trackingInfo: {},
      showModal: false,
      selectedOrder: "",
      isModalVisible: false,
    };
  },
  computed: {
    defaultCurrency() {
      return this.listOfOrders[0] && this.listOfOrders[0].currency !== ""
        ? this.listOfOrders[0].currency
        : "EUR";
    },
  },
  mounted() {
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isShopify = true;
      this.getOrders();
    } else {
      this.getOrders();
      CommonData.getCurrentUser()
        .then(function (response) {
          this.userType = response.data.role;

          setVariable("role", this.userType);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  methods: {
    getOrders() {
      SupplierOrders.getOrders()
        .then((response) => {
          console.log("res", response);
          this.listOfOrders = response.data.orders;
        })
        .catch((e) => {
          console.log(e);
        });
      this.totalOrderCount = 10;
      this.page_size = Math.ceil(this.totalOrderCount / this.count);
    },
    updateTrackingInfoValue(type, data) {
      this.trackingInfo[type] = data;
    },
    async saveChange() {
      if (!this.trackingInfo["carrier_name"]) {
        alert("Carrier name can't be empty");
        return;
      }
      if (!this.trackingInfo["tracking_number"]) {
        alert("Tracking number can't be empty");
        return;
      }
      if (!this.trackingInfo["tracking_url"]) {
        alert("Tracking URL can't be empty");
        return;
      }
      this.trackingInfo["order_external_id"] = this.selectedOrder;

      SupplierOrderTrackingInfo.updateOrderTrackingInfo(this.trackingInfo)
        .then((response) => {
          alert("Your changes have been saved");
        })
        .catch((e) => {
          alert("Something went wrong");
        });
      this.showModal = false;
    },
    next(page) {
      console.log(page);
      this.getOrders();
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    addRemainingSecondsToDate(date, seconds) {
      var d = new Date(date);
      d.setSeconds(d.getSeconds() + seconds);
      return d;
    },

    close() {
      this.$emit("close");
    },

    showTrackingModal(selectedOrder) {
      this.showModal = true;
      this.selectedOrder = selectedOrder;
      this.trackingInfo = {};
    },
    closeModal() {
      this.isModalVisible = false;
    },
    toCurrency: function (price, currency) {
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: currency,
      });
      const curerncy_price = formatter.format(price);

      return curerncy_price;
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.shopify-table-title {
  padding-bottom: 30px;
}
.trackInfo {
  margin-left: 40px;
}
.cancel {
  width: 103px;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 24px;
  font-size: 14px;
  font-weight: bold;
  color: #1d2939;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(16, 24, 40, 0.05);
  &.outlined {
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05),
      0px 1px 2px rgba(16, 24, 40, 0.05);
    background: transparent;
  }
}
.cancle {
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;
  width: 71px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
}
.tracking {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  margin-bottom: 4px;
  margin-top: 16px;
}
.close {
  position: absolute;
  width: 40px;
  top: 24px;
  right: 24px;
  background: #008f4a;
  color: white;
  padding: 10px;
  border: 1px solid #008f4a;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}
.closeBt {
  width: 20px;
  top: 24px;
  right: 24px;
}
.no-access {
  border-radius: 10px;
  text-align: center;
  color: #ff0a43;
  padding: 30px;
  border: 1px solid #ff0a43;
}
.orderlist-table {
  width: 100%;
  background: white;
  margin: 12px;
  border-radius: 10px;
  th {
    padding-top: 26px;
    padding-bottom: 26px;
    border-bottom: 1px solid #ededed;
  }
  td {
    //padding-top: 26px;
    //padding-bottom: 26px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #111418;
  }
  .dropdown-menu {
    max-height: 150px;
    overflow: visible;
    overflow-y: scroll;
  }
  .dots {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
  .each-row {
    border-bottom: 1px solid #ededed;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
    .button {
      border: 1px solid #008f4a;
      color: #008f4a;
      border-radius: 10px;
      padding: 10px 24px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .status {
    font-size: 12px;
    padding: 5px 10px;
    .status-icon {
      vertical-align: text-bottom;
      margin-right: 5px;
      display: inline-block;
      font-size: 18px;
    }
    &.waiting {
      color: white;
      background: #00e676;
      border-radius: 5px;
    }
    &.delivered-to-post {
      color: white;
      background: #0ac2ff;
      border-radius: 5px;
    }
    &.pending {
      color: white;
      background: #ff7e9d;
      border-radius: 5px;
    }
    &.paid {
      color: #008f4a;
      border-radius: 5px;
      border: 1px solid #008f4a;
    }
  }
}
.shopify-table {
  .status {
    font-size: 12px;
    padding: 5px 10px;
    .status-icon {
      vertical-align: text-bottom;
      margin-right: 5px;
      display: inline-block;
      font-size: 18px;
    }
    &.waiting {
      color: white;
      background: #00e676;
      border-radius: 5px;
    }
    &.delivered-to-post {
      color: white;
      background: #0ac2ff;
      border-radius: 5px;
    }
    &.pending {
      color: white;
      background: #ff7e9d;
      border-radius: 5px;
    }
    &.paid {
      color: #008f4a;
      border-radius: 5px;
      border: 1px solid #008f4a;
    }
  }
}
.np-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f7;
  padding: 20px;
  margin-top: 180px !important;
  ._link {
    margin-left: 32px;
    margin-right: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #435160;
  }
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  //position: fixed;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  overflow-y: auto;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  left: 25%;
  top: 25%;
  flex-direction: column;
  width: 640px;
  max-height: 472px;
  width: 100%;
  position: absolute;
  @media (max-width: 768px) {
    left: 0%;
  }
  @media (min-width: 768px) and(max-width:992px) {
    left: 9%;
  }
  @media (min-width: 992px) and(max-width:1210px) {
    left: 18%;
  }
}

.modal-header,
.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  height: 88px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #1d2939;
  background: #f2f4f7;
}

.saveChange {
  border-top: 1px #ffffff;
  margin-top: 16px;
}

.modal-body {
  position: relative;
  margin: 30px 48px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
